
// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #1e355a !default;
$color-secondary: #3b5c91 !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;
$color-link: #365d9b !default;