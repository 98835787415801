@import "https://fonts.googleapis.com/css?family=Amiri:400,400i,700,700i";
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.618em;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: #3b5c91;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6;
}

blockquote {
  border-left: 0.3rem solid #d1d1d1;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}

blockquote *:last-child {
  margin-bottom: 0;
}

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background-color: #1e355a;
  border: 2px solid #1e355a;
  border-radius: .4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .2rem;
  line-height: 3.5rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

.button:focus, .button:hover,
button:focus,
button:hover,
input[type='button']:focus,
input[type='button']:hover,
input[type='reset']:focus,
input[type='reset']:hover,
input[type='submit']:focus,
input[type='submit']:hover {
  background-color: #3b5c91;
  border-color: #3b5c91;
  color: #fff;
  outline: 0;
}

.button[disabled],
button[disabled],
input[type='button'][disabled],
input[type='reset'][disabled],
input[type='submit'][disabled] {
  cursor: default;
  opacity: .5;
}

.button[disabled]:focus, .button[disabled]:hover,
button[disabled]:focus,
button[disabled]:hover,
input[type='button'][disabled]:focus,
input[type='button'][disabled]:hover,
input[type='reset'][disabled]:focus,
input[type='reset'][disabled]:hover,
input[type='submit'][disabled]:focus,
input[type='submit'][disabled]:hover {
  background-color: #1e355a;
  border-color: #1e355a;
}

.button.button-outline,
button.button-outline,
input[type='button'].button-outline,
input[type='reset'].button-outline,
input[type='submit'].button-outline {
  background-color: transparent;
  color: #1e355a;
}

.button.button-outline:focus, .button.button-outline:hover,
button.button-outline:focus,
button.button-outline:hover,
input[type='button'].button-outline:focus,
input[type='button'].button-outline:hover,
input[type='reset'].button-outline:focus,
input[type='reset'].button-outline:hover,
input[type='submit'].button-outline:focus,
input[type='submit'].button-outline:hover {
  background-color: transparent;
  border-color: #3b5c91;
  color: #3b5c91;
}

.button.button-outline[disabled]:focus, .button.button-outline[disabled]:hover,
button.button-outline[disabled]:focus,
button.button-outline[disabled]:hover,
input[type='button'].button-outline[disabled]:focus,
input[type='button'].button-outline[disabled]:hover,
input[type='reset'].button-outline[disabled]:focus,
input[type='reset'].button-outline[disabled]:hover,
input[type='submit'].button-outline[disabled]:focus,
input[type='submit'].button-outline[disabled]:hover {
  border-color: inherit;
  color: #1e355a;
}

.button.button-ghost,
button.button-ghost,
input[type='button'].button-ghost,
input[type='reset'].button-ghost,
input[type='submit'].button-ghost {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.button.button-ghost:focus, .button.button-ghost:hover,
button.button-ghost:focus,
button.button-ghost:hover,
input[type='button'].button-ghost:focus,
input[type='button'].button-ghost:hover,
input[type='reset'].button-ghost:focus,
input[type='reset'].button-ghost:hover,
input[type='submit'].button-ghost:focus,
input[type='submit'].button-ghost:hover {
  background-color: #3b5c91;
  color: #fff;
}

.button.button-ghost[disabled]:focus, .button.button-ghost[disabled]:hover,
button.button-ghost[disabled]:focus,
button.button-ghost[disabled]:hover,
input[type='button'].button-ghost[disabled]:focus,
input[type='button'].button-ghost[disabled]:hover,
input[type='reset'].button-ghost[disabled]:focus,
input[type='reset'].button-ghost[disabled]:hover,
input[type='submit'].button-ghost[disabled]:focus,
input[type='submit'].button-ghost[disabled]:hover {
  border-color: inherit;
  color: #fff;
}

.button.button-clear,
button.button-clear,
input[type='button'].button-clear,
input[type='reset'].button-clear,
input[type='submit'].button-clear {
  background-color: transparent;
  border-color: transparent;
  color: #1e355a;
}

.button.button-clear:focus, .button.button-clear:hover,
button.button-clear:focus,
button.button-clear:hover,
input[type='button'].button-clear:focus,
input[type='button'].button-clear:hover,
input[type='reset'].button-clear:focus,
input[type='reset'].button-clear:hover,
input[type='submit'].button-clear:focus,
input[type='submit'].button-clear:hover {
  background-color: transparent;
  border-color: transparent;
  color: #3b5c91;
}

.button.button-clear[disabled]:focus, .button.button-clear[disabled]:hover,
button.button-clear[disabled]:focus,
button.button-clear[disabled]:hover,
input[type='button'].button-clear[disabled]:focus,
input[type='button'].button-clear[disabled]:hover,
input[type='reset'].button-clear[disabled]:focus,
input[type='reset'].button-clear[disabled]:hover,
input[type='submit'].button-clear[disabled]:focus,
input[type='submit'].button-clear[disabled]:hover {
  color: #1e355a;
}

code {
  background: #f4f5f6;
  border-radius: .4rem;
  font-size: 86%;
  margin: 0 .2rem;
  padding: .2rem .5rem;
  white-space: nowrap;
}

pre {
  background: #f4f5f6;
  border-left: 0.3rem solid #1e355a;
  overflow-y: hidden;
}

pre > code {
  border-radius: 0;
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

hr {
  border: 0;
  border-top: 2px solid rgba(30, 53, 90, 0.1);
  margin: 3.0rem 0;
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  font-size: 1.6rem;
  appearance: none;
  background-color: transparent;
  border: 2px solid #d1d1d1;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: .6rem 1.0rem;
  width: 100%;
}

input[type='email']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='url']:focus,
textarea:focus,
select:focus {
  border-color: #1e355a;
  outline: 0;
}

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
  padding-right: 3.0rem;
}

select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#9b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
}

textarea {
  min-height: 6.5rem;
}

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: .5rem;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: .5rem;
}

a {
  color: #365d9b;
  text-decoration: none;
  font-weight: 600;
  opacity: .75;
}

a:focus, a:hover {
  opacity: 1;
  text-decoration: underline;
}

dl,
ol,
ul {
  padding-left: 2rem;
}

dl li,
ol li,
ul li {
  margin-left: 0rem;
}

dl dl,
dl ol,
dl ul,
ol dl,
ol ol,
ol ul,
ul dl,
ul ol,
ul ul {
  font-size: 90%;
  margin: 1.5rem 0 1.5rem 3.0rem;
}

ol {
  list-style: decimal;
}

ul {
  list-style: circle;
}

table {
  border-spacing: 0;
  width: 100%;
}

td,
th {
  border-bottom: 0.1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
  text-align: left;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

.button,
button,
dd,
dt,
li {
  margin-bottom: 1.0rem;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem;
}

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 2.5rem;
}

b,
strong {
  font-weight: bold;
}

p {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -.1rem;
  margin-bottom: 2.0rem;
  margin-top: 0;
}

h1 {
  font: 600 4.6rem/1.2 'Amiri',serif;
}

h2 {
  font: 600 3.6rem/1.25 'Amiri',serif;
}

h3 {
  font: 600 2.8rem/1.3 'Amiri',serif;
}

h4 {
  font: 400 2.2rem/1.35 'Amiri',serif;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.clearfix:after {
  clear: both;
  content: ' ';
  display: table;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.container, .headline, .compass, .cycle {
  max-width: 700px;
  margin: 0 auto;
}

.container {
  padding: 4em 2em;
}

.container-lg, .about > section {
  max-width: 900px;
  margin: 0 auto;
}

.container-lg {
  padding: 4em 2em;
}

.main-menu ul, .principles ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

body {
  color: #37474f;
  margin-top: 4em;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  padding: 0;
  font: 400 20px/1.6 "Source Sans Pro", sans-serif;
  min-height: 100%;
  background: #f2f2f2;
  margin: 0;
}

main {
  min-height: 100vh;
  position: relative;
  top: 4em;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

h4 {
  font: 700 22px/1.3 "Amiri", serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0.25em;
}

em {
  font-style: italic;
}

strong {
  font-weight: 600;
}

footer {
  position: relative;
  top: 4em;
}

.footer {
  color: #fff;
  background: rgba(42, 56, 78, 0.8);
  background-image: url(../../assets/img/grain.png);
}

.footer > div {
  display: flex;
}

.footer section {
  flex-grow: 1;
  padding: 1em;
}

.footer section h2 {
  font: 700 1.25em/1.5 "Amiri", serif;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
}

.footer section p {
  margin-bottom: 0.5em;
}

.footer section a {
  color: #fff;
}

.main-menu-wrapper {
  position: fixed;
  top: 0;
  max-height: 4em;
  padding: 0.75em 1.5em;
  background: #1e355a;
  overflow: hidden;
  width: 100%;
  z-index: 10;
  transition: max-height 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .main-menu-wrapper {
    display: flex;
    align-items: center;
  }
}

.main-menu-wrapper a {
  color: #fff;
  text-decoration: none;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.logo {
  display: flex;
  flex-direction: row;
  height: 3em;
  opacity: 1;
  /*@include md {
		flex-direction:column;
		height:auto;
	}*/
}

.img-logo {
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.5));
  width: 3em;
  margin-right: 0.5em;
}

.img-title {
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.5));
  padding-top: 0.25em;
  width: 9em;
}

@media (min-width: 992px) {
  .main-menu {
    margin: 0 0 0 auto;
  }
}

.main-menu ul {
  margin-top: 1em;
}

@media (min-width: 992px) {
  .main-menu ul {
    margin: 0;
  }
}

.main-menu ul > li.page-link {
  margin: 0.5em 0;
}

@media (min-width: 992px) {
  .main-menu ul > li.page-link {
    display: inline-block;
    margin: 0 1em 0 0;
  }
}

.main-menu ul > li.page-link a {
  font-weight: 600;
  padding: 0.5em 0;
  display: block;
  width: 100%;
}

li.client-login {
  margin: 2em 0;
}

@media (min-width: 992px) {
  li.client-login {
    display: inline-block;
  }
}

li.client-login a {
  padding: 1rem 2rem;
  line-height: 1;
  margin: 0;
}

.menu-toggle {
  position: fixed;
  top: 1em;
  right: 1.5em;
  width: 3em;
  height: 3em;
  padding: 0.5em;
  overflow: hidden;
  display: block;
}

@media (min-width: 992px) {
  .menu-toggle {
    display: none;
  }
}

.menu-toggle span {
  width: 100%;
  background-color: #fff;
  content: "";
  display: block;
  height: 2px;
  margin: 0.65em 0;
  transition: all 0.2s ease-in-out;
}

.menu-toggle span:first-child {
  margin-top: 0;
}

.menu-toggle span:last-child {
  margin-bottom: 0;
}

[data-menu-state="active"] .menu-toggle span {
  transform: scale(0);
}

[data-menu-state="active"] .menu-toggle span:first-child {
  transform: translateY(0.75em) rotate(135deg);
}

[data-menu-state="active"] .menu-toggle span:last-child {
  transform: translateY(-0.75em) rotate(-135deg);
}

[data-menu-state="active"] .main-menu-wrapper {
  max-height: 100vh;
}

.principles {
  text-align: center;
  background: #fafafa;
  padding: 2em;
}

.principles h2 {
  font: 700 30px/1.2 "Amiri", serif;
  font-style: italic;
  margin: 1em 0 2em 0;
}

.principles ol {
  font-size: 0.85em;
  display: grid;
  grid-gap: 1em;
  counter-reset: number;
}

@media (min-width: 768px) {
  .principles ol {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}

@media (min-width: 1200px) {
  .principles ol {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.principles ol > li {
  counter-increment: number;
  border: 4px solid rgba(30, 53, 90, 0.1);
  position: relative;
  max-width: 50ch;
  margin: 1em auto;
  padding: 2em 1em 1em 1em;
  background: rgba(255, 255, 255, 0.5);
}

.principles ol > li:before {
  content: counter(number) " ";
  left: 0;
  left: 50%;
  transform: translate(-50%, -2em);
  position: absolute;
  font: 700 2em/1.5 "Amiri", serif;
  display: block;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  border-radius: 3em;
  border: 4px solid rgba(30, 53, 90, 0.1);
  background: #fff;
  color: rgba(30, 53, 90, 0.5);
}

.svg-wrapper {
  margin-bottom: 2em;
}

.svg-wrapper svg {
  filter: drop-shadow(5px 5px 12px #fff);
}

.svg-wrapper path {
  fill: #1e355a;
}

.hero {
  overflow: hidden;
  font-size: calc(0.75em + 0.4vw);
  text-align: center;
}

.hero .content-wrapper {
  background-size: cover;
  background-position: center;
}

.hero .text {
  max-width: 50ch;
  padding: 4em 2em;
  margin: 0 auto;
  opacity: 0.9;
}

.hero p {
  font: 700 1.5em/1.2 "Amiri", serif;
  margin-bottom: 0.1em;
  font-style: italic;
  color: #1e355a;
}

.headline {
  padding: 3em 2em;
}

.headline h2 {
  font: 700 30px/1.2 "Amiri", serif;
  margin-bottom: 0.5em;
  color: #1e355a;
}

.about > section {
  padding: 3em 2em;
}

@media (min-width: 768px) {
  .about > section {
    display: flex;
  }
}

.about section.about-ridgewood {
  display: block;
}

.about img {
  border: 4px solid rgba(30, 53, 90, 0.1);
  padding: 0.25em;
  max-width: 200px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .about img {
    margin-right: 2em;
  }
}

.process section:nth-child(even),
.what-we-do section:nth-child(even),
.legal section:nth-child(even) {
  background: #fafafa;
}

.compass {
  text-align: center;
}

.compass ol {
  margin: 0;
  padding: 1em 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 2em;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  position: relative;
}

@media (min-width: 768px) {
  .compass ol {
    font-size: 1em;
  }
}

.compass ol:after {
  content: "";
  height: 30em;
  width: 30em;
  position: absolute;
  left: 50%;
  margin-left: -15em;
  top: 50%;
  margin-top: -15em;
  background-image: url(../../assets/img/compass.svg);
  background-size: cover;
  z-index: 0;
  opacity: 0.1;
}

.compass ol h4 {
  font-size: 0.9em;
  color: #1e355a;
}

.compass ol h6 {
  margin: 0;
}

.compass ol li {
  position: relative;
  z-index: 1;
  background: rgba(250, 250, 250, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 4px solid rgba(18, 50, 102, 0.3);
  padding: 1em 0.5em;
  text-align: center;
}

.compass ol li p {
  margin-bottom: 0.5em;
}

.compass ol li:nth-child(1) {
  grid-column: 2/4;
  grid-row: 1;
  position: relative;
}

.compass ol li:nth-child(2) {
  grid-column: 3/5;
  grid-row: 2;
}

.compass ol li:nth-child(3) {
  grid-column: 2/4;
  grid-row: 3;
  position: relative;
}

.compass ol li:nth-child(4) {
  grid-column: 1/3;
  grid-row: 2;
}

.cycle ol {
  margin: 0;
  padding: 1em 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}

@media (min-width: 768px) {
  .cycle ol {
    font-size: 1em;
  }
}

.cycle ol h4 {
  font-size: 0.9em;
  color: #1e355a;
}

.cycle ol h6 {
  margin: 0;
}

.cycle ol li {
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 4px solid rgba(18, 50, 102, 0.3);
  padding: 1em 0.5em;
  text-align: center;
}

.cycle ol li p {
  margin-bottom: 0.5em;
}

.cycle ol li:nth-child(1) {
  grid-column: 2/4;
  grid-row: 1;
  position: relative;
}

.cycle ol li:nth-child(1):before {
  content: "";
  position: absolute;
  bottom: 0em;
  left: -6em;
  width: 5em;
  height: 5em;
  background-image: url(../../assets/img/arrow.svg);
  background-size: 5em, 5em;
  transform: rotate(-90deg);
  opacity: 0.75;
}

.cycle ol li:nth-child(1):after {
  content: "";
  position: absolute;
  bottom: 0em;
  right: -6em;
  width: 5em;
  height: 5em;
  background-image: url(../../assets/img/arrow.svg);
  background-size: 5em, 5em;
  transform: rotate(0deg);
  opacity: 0.75;
}

.cycle ol li:nth-child(2) {
  grid-column: 3/5;
  grid-row: 2;
}

.cycle ol li:nth-child(3) {
  grid-column: 2/4;
  grid-row: 3;
  position: relative;
}

.cycle ol li:nth-child(3):before {
  content: "";
  position: absolute;
  top: 0em;
  left: -6em;
  width: 5em;
  height: 5em;
  background-image: url(../../assets/img/arrow.svg);
  background-size: 5em, 5em;
  transform: rotate(180deg);
  opacity: 0.75;
}

.cycle ol li:nth-child(3):after {
  content: "";
  position: absolute;
  top: 0em;
  right: -6em;
  width: 5em;
  height: 5em;
  background-image: url(../../assets/img/arrow.svg);
  background-size: 5em, 5em;
  transform: rotate(90deg);
  opacity: 0.75;
}

.cycle ol li:nth-child(4) {
  grid-column: 1/3;
  grid-row: 2;
}

.cta {
  padding-top: 1em;
  font-size: 1.1em;
  text-align: center;
  background: rgba(0, 46, 120, 0.2);
}
