
// Table
// ––––––––––––––––––––––––––––––––––––––––––––––––––

table {
	border-spacing: 0;
	width: 100%;
}

td,
th {
	border-bottom: .1rem solid $color-quinary;
	padding: 1.2rem 1.5rem;
	text-align: left;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}
