
// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

a {
	color: $color-link;
	text-decoration: none;
	font-weight:600;
	opacity:.75;

	&:focus,
	&:hover {
		opacity:1;
		text-decoration:underline;
	}
}
