
// List
// ––––––––––––––––––––––––––––––––––––––––––––––––––

dl,
ol,
ul {
	padding-left:2rem;
	li{
		margin-left:0rem;
	}
	dl,
	ol,
	ul {
		font-size: 90%;
		margin: 1.5rem 0 1.5rem 3.0rem;
	}
}

ol {
	list-style: decimal;
}

ul {
	list-style: circle;
}
