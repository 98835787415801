
// Typography
// ––––––––––––––––––––––––––––––––––––––––––––––––––

b,
strong {
	font-weight: bold;
}

p {
	margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 300;
	letter-spacing: -.1rem;
	margin-bottom: 2.0rem;
	margin-top: 0;
}

h1 {
	font: 600 4.6rem/1.2 'Amiri',serif;
}

h2 {
	font: 600 3.6rem/1.25 'Amiri',serif;
}

h3 {
	font: 600 2.8rem/1.3 'Amiri',serif;
}

h4 {
	font: 400 2.2rem/1.35 'Amiri',serif;
}

h5 {
	font-size: 1.8rem;
	letter-spacing: -.05rem;
	line-height: 1.5;
}

h6 {
	font-size: 1.6rem;
	letter-spacing: 0;
	line-height: 1.4;
}
