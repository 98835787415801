// Reset
@import "https://fonts.googleapis.com/css?family=Amiri:400,400i,700,700i";
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700";
@import "reset";
@import "Color";
@import "Base";
@import "Blockquote";
@import "Button";
@import "Code";
@import "Divider";
@import "Form";
@import "Link";
@import "List";
@import "Table";
@import "Spacing";
@import "Typography";
@import "Image";
@import "Utility";

$background: #f2f2f2;
$border: rgba($color-primary, 0.1);
$menu-height: 4em;

// Screen size variables
$screen-xs-min: 425px; // Tiny phones
$screen-sm-min: 576px; // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px; // Small tablets (portrait view)
$screen-lg-min: 992px; // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

// Mixins
@mixin xs {
	@media (min-width: #{$screen-xs-min}) {
		@content;
	}
}

// Tiny devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Small devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Medium devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

// Large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

// Extra large devices

%container {
	max-width: 700px;
	margin: 0 auto;
}

.container {
	@extend %container;
	padding: 4em 2em;
}

%container-lg {
	max-width: 900px;
	margin: 0 auto;
}

.container-lg {
	@extend %container-lg;
	padding: 4em 2em;
}

%flat-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

body {
	color: #37474f;
	margin-top: $menu-height;
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
	padding: 0;
	font: 400 20px/1.6 "Source Sans Pro", sans-serif;
	min-height: 100%;
	background: $background;
	margin: 0;
}

main {
	min-height: 100vh;
	position: relative;
	top: $menu-height;
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

h4 {
	font: 700 22px/1.3 "Amiri", serif;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin-bottom: 0.25em;
}

em {
	font-style: italic;
}

strong {
	font-weight: 600;
}

footer {
	position: relative;
	top: $menu-height;
}

.footer {
	color: #fff;
	background: desaturate(rgba($color-primary, 0.8), 20%);
	background-image: url(../../assets/img/grain.png);

	> div {
		display: flex;
	}

	section {
		flex-grow: 1;
		padding: 1em;

		h2 {
			font: 700 1.25em/1.5 "Amiri", serif;
			opacity: 0.8;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			border-bottom: 4px solid rgba(#fff, 0.2);
		}

		p {
			margin-bottom: 0.5em;
		}

		a {
			color: #fff;
		}
	}
}

.main-menu-wrapper {
	position: fixed;
	top: 0;
	max-height: $menu-height;
	padding: 0.75em 1.5em;
	background: $color-primary;
	overflow: hidden;
	width: 100%;
	z-index: 10;

	transition: max-height 0.2s ease-in-out;

	@include lg {
		display: flex;
		align-items: center;
	}

	a {
		color: #fff;
		text-decoration: none;
		font-size: 0.85em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}
}

.logo {
	display: flex;
	flex-direction: row;
	height: 3em;
	opacity: 1;
	/*@include md {
		flex-direction:column;
		height:auto;
	}*/
}

.img-logo {
	filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.5));
	width: 3em;
	margin-right: 0.5em;
}

.img-title {
	filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.5));
	padding-top: 0.25em;
	width: 9em;
}

.main-menu {
	@include lg {
		margin: 0 0 0 auto;
	}

	ul {
		@extend %flat-list;
		//font-size:.9em;
		margin-top: 1em;

		@include lg {
			margin: 0;
		}

		> li.page-link {
			margin: 0.5em 0;

			@include lg {
				display: inline-block;
				margin: 0 1em 0 0;
			}

			a {
				font-weight: 600;
				padding: 0.5em 0;
				display: block;
				width: 100%;
			}
		}
	}
}

li.client-login {
	margin: 2em 0;

	@include lg {
		display: inline-block;
	}

	a {
		padding: 1rem 2rem;
		line-height: 1;
		margin: 0;
	}
}

.menu-toggle {
	position: fixed;
	top: 1em;
	right: 1.5em;
	width: 3em;
	height: 3em;
	padding: 0.5em;
	overflow: hidden;
	display: block;
	//border: 1px solid red;
	@include lg {
		display: none;
	}
	span {
		width: 100%;
		background-color: #fff;
		content: "";
		display: block;
		height: 2px;
		margin: 0.65em 0;
		transition: all 0.2s ease-in-out;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

[data-menu-state="active"] {
	.menu-toggle {
		span {
			transform: scale(0);
			&:first-child {
				transform: translateY(0.75em) rotate(135deg);
			}
			&:last-child {
				transform: translateY(-0.75em) rotate(-135deg);
			}
		}
	}

	.main-menu-wrapper {
		max-height: 100vh;
	}
}

.principles {
	text-align: center;
	background: #fafafa;
	padding: 2em;

	h2 {
		font: 700 30px/1.2 "Amiri", serif;
		font-style: italic;
		margin: 1em 0 2em 0;
	}

	ol {
		@extend %flat-list;
		font-size: 0.85em;
		display: grid;
		grid-gap: 1em;

		@include md {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
		}

		@include xl {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		counter-reset: number;

		> li {
			counter-increment: number;
			border: 4px solid rgba($color-primary, 0.1);
			position: relative;
			max-width: 50ch;
			margin: 1em auto;
			padding: 2em 1em 1em 1em;
			background: rgba(255, 255, 255, 0.5);

			&:before {
				content: counter(number) " ";
				left: 0;
				left: 50%;
				transform: translate(-50%, -2em);
				position: absolute;
				font: 700 2em/1.5 "Amiri", serif;
				display: block;
				height: 1.5em;
				width: 1.5em;
				text-align: center;
				border-radius: 3em;
				border: 4px solid rgba($color-primary, 0.1);
				background: #fff;
				color: rgba($color-primary, 0.5);
			}
		}
	}
}

.svg-wrapper {
	margin-bottom: 2em;

	svg {
		filter: drop-shadow(5px 5px 12px #fff);
	}

	path {
		fill: $color-primary;
	}
}

.hero {
	overflow: hidden;
	font-size: calc(0.75em + 0.4vw);
	text-align: center;

	.content-wrapper {
		background-size: cover;
		background-position: center;
	}

	.text {
		max-width: 50ch;
		padding: 4em 2em;
		margin: 0 auto;
		opacity: 0.9;
	}

	p {
		font: 700 1.5em/1.2 "Amiri", serif;
		margin-bottom: 0.1em;
		font-style: italic;
		color: $color-primary;
	}
}

.headline {
	@extend %container;
	padding: 3em 2em;

	h2 {
		font: 700 30px/1.2 "Amiri", serif;
		margin-bottom: 0.5em;
		color: $color-primary;
	}
}

.about {
	> section {
		@extend %container-lg;
		padding: 3em 2em;

		@include md {
			display: flex;
		}
	}

	section.about-ridgewood {
		display: block;
	}

	img {
		border: 4px solid rgba($color-primary, 0.1);
		padding: 0.25em;
		max-width: 200px;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include md {
			margin-right: 2em;
		}
	}
}

.process,
.what-we-do,
.legal {
	section {
		&:nth-child(even) {
			background: #fafafa;
		}
	}
}

.compass {
	text-align: center;
	@extend %container;

	ol {
		margin: 0;
		padding: 1em 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-rows: auto;
		grid-gap: 2em;
		align-items: center;
		justify-content: center;
		font-size: 0.8em;
		position: relative;

		@include md {
			font-size: 1em;
		}

		&:after {
			content: "";
			height: 30em;
			width: 30em;
			position: absolute;
			left: 50%;
			margin-left: -15em;
			top: 50%;
			margin-top: -15em;
			background-image: url(../../assets/img/compass.svg);
			background-size: cover;
			z-index: 0;
			opacity: 0.1;
		}

		h4 {
			font-size: 0.9em;
			color: $color-primary;
		}

		h6 {
			margin: 0;
		}

		li {
			position: relative;
			z-index: 1;
			background: rgba(#fafafa, 0.8);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			border: 4px solid saturate(rgba($color-primary, 0.3), 20%);
			padding: 1em 0.5em;
			text-align: center;

			p {
				margin-bottom: 0.5em;
			}

			&:nth-child(1) {
				grid-column: 2/4;
				grid-row: 1;
				position: relative;
			}

			&:nth-child(2) {
				grid-column: 3/5;
				grid-row: 2;
			}

			&:nth-child(3) {
				grid-column: 2/4;
				grid-row: 3;
				position: relative;
			}

			&:nth-child(4) {
				grid-column: 1/3;
				grid-row: 2;
			}
		}
	}
}

.cycle {
	@extend %container;

	ol {
		margin: 0;
		padding: 1em 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-rows: auto;
		grid-gap: 1em;
		align-items: center;
		justify-content: center;
		font-size: 0.8em;

		@include md {
			font-size: 1em;
		}

		h4 {
			font-size: 0.9em;
			color: $color-primary;
		}

		h6 {
			margin: 0;
		}

		li {
			background: #fafafa;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			border: 4px solid saturate(rgba($color-primary, 0.3), 20%);
			padding: 1em 0.5em;
			text-align: center;

			p {
				margin-bottom: 0.5em;
			}

			&:nth-child(1) {
				grid-column: 2/4;
				grid-row: 1;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					bottom: 0em;
					left: -6em;
					width: 5em;
					height: 5em;
					background-image: url(../../assets/img/arrow.svg);
					background-size: 5em, 5em;
					transform: rotate(-90deg);
					opacity: 0.75;
				}

				&:after {
					content: "";
					position: absolute;
					bottom: 0em;
					right: -6em;
					width: 5em;
					height: 5em;
					background-image: url(../../assets/img/arrow.svg);
					background-size: 5em, 5em;
					transform: rotate(0deg);
					opacity: 0.75;
				}
			}

			&:nth-child(2) {
				grid-column: 3/5;
				grid-row: 2;
			}

			&:nth-child(3) {
				grid-column: 2/4;
				grid-row: 3;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 0em;
					left: -6em;
					width: 5em;
					height: 5em;
					background-image: url(../../assets/img/arrow.svg);
					background-size: 5em, 5em;
					transform: rotate(180deg);
					opacity: 0.75;
				}

				&:after {
					content: "";
					position: absolute;
					top: 0em;
					right: -6em;
					width: 5em;
					height: 5em;
					background-image: url(../../assets/img/arrow.svg);
					background-size: 5em, 5em;
					transform: rotate(90deg);
					opacity: 0.75;
				}
			}

			&:nth-child(4) {
				grid-column: 1/3;
				grid-row: 2;
			}
		}
	}
}

.cta {
	padding-top: 1em;
	font-size: 1.1em;
	text-align: center;
	background: saturate(rgba($color-primary, 0.2), 50%);
}
